import React, { useEffect, useLayoutEffect } from "react";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Countdown from "react-countdown";

import { rendererOne } from "../features/count-down";
import LogoImage from "../common/logo";

function ComingSoon({title,description,comingSoon}) {

  // const { site, contact } = useStaticQuery(
  //   graphql`
  //     {
  //       contact {
  //         instagram
  //         facebook
  //         message
  //       }
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //           siteUrl
  //           hideStore {
  //             enableComingSoon
  //             launchDate
  //             customText
  //           }
  //         }
  //       }
  //     }
  //   `
  // );
  useLayoutEffect(() => {
    document.querySelector("body").classList.remove("loaded");
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.querySelector("body").classList.add("loaded");
    }, 200);
  }, []);
  const customText = "Launching Soon";
  return (
    <div className="soon">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <h1 className="d-none">{title}</h1>

      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="soon-content text-center">
              <div className="soon-content-wrapper m-auto">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/images/logo-icon.png`}
                  alt="Logo"
                  className="soon-logo mx-auto"
                /> */}
             <div className="d-flex justify-content-center">   <LogoImage /></div>

                <h1 className="soon-title">{title}</h1>

                <div className="coming-countdown countdown-separator">
                {/* {comingSoon.launchDate} */}
                  <Countdown
                    date={comingSoon.launchDate}
                    renderer={rendererOne}
                  />
                </div>

                <hr className="mt-2 mb-3 mt-md-3" />

                <p>{comingSoon?.customText}</p>

                {/* <form action="#">
                  <div className="input-group mb-5">
                    <input
                      type="email"
                      className="form-control bg-transparent"
                      placeholder="Enter your Email Address"
                      required
                    />
                    <div className="input-group-append">
                      <button
                        className="btn btn-outline-primary-2"
                        type="submit"
                      >
                        <span>SUBSCRIBE</span>
                        <i className="icon-long-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </form> */}

                <div className="social-icons justify-content-center mb-0">
                  {/* <Link
                    to={contact.facebook}
                    className="social-icon"
                    target="_blank"
                    title="Facebook"
                  >
                    <i className="icon-facebook-f"></i>
                  </Link> */}
                  {/* <Link
                    to="#"
                    className="social-icon"
                    target="_blank"
                    title="Twitter"
                  >
                    <i className="icon-twitter"></i>
                  </Link> */}
                  {/* <Link
                    to={contact.instagram}
                    className="social-icon"
                    target="_blank"
                    title="Instagram"
                  >
                    <i className="icon-instagram"></i>
                  </Link> */}
                  {/* <Link
                    to="#"
                    className="social-icon"
                    target="_blank"
                    title="Youtube"
                  > */}
                  {/* <i className="icon-youtube"></i>
                  </Link> */}
                  {/* <Link
                    to="#"
                    className="social-icon"
                    target="_blank"
                    title="Pinterest"
                  >
                    <i className="icon-pinterest"></i>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="soon-bg bg-image"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/soon-bg.jpg)`,
        }}
      ></div> */}
    </div>
  );
}

export default React.memo(ComingSoon);
