import React from "react";
import MainLayout from "../layouts/MainLayout";
import Banner from "../components/home/banner";
import Service from "../components/features/service";
// import Custom Components
import NewsletterModal from "../components/features/modal/newsletter-modal";
import Products from "../components/home/Products";
import RecentlyViewed from "../components/home/RecentlyViewed";
import SubscriptionForm from "../components/common/SubscriptionForm";
import Seo from "../components/common/SEO";
import { graphql } from "gatsby";
import HomeSlider from "../components/home/HomeSlider";
import ComingSoon from "../components/home/comingSoon";

const Index = ({ data, location }) => {
  console.log("Data", data);
  return (
    <>
      {data?.allMetadata?.nodes[0]?.hideStore?.enableComingSoon ? (
        <ComingSoon
          comingSoon={data?.allMetadata?.nodes[0]?.hideStore}
          title={data?.allMetadata?.nodes[0]?.title}
          description={data?.allMetadata?.nodes[0]?.description}
        />
      ) : (
        <MainLayout>
          <div className="main home-page">
            <Seo
              description={data?.allMetadata?.nodes[0]?.description}
              title={data?.allMetadata?.nodes[0]?.title}
            />
            {/* Home Banner */}
            <HomeSlider />
            <div className="container">
              <h2 className="title text-center mt-6 mb-4">
                Explore Popular Categories
              </h2>

              <div className="cat-blocks-container">
                <div className="row">
                  {data.allFeatCategories.nodes.map((item, index) => (
                    <Banner data={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
            <div className="mb-6"></div>

            <Products />
            <Service />
            <RecentlyViewed />
          </div>
          <SubscriptionForm />
          <NewsletterModal demo="4" />
        </MainLayout>
      )}
    </>
  );
};

export default Index;
export const query = graphql`
  query MyQuery {
    allMetadata {
      nodes {
        description
        title
        hideStore {
          enableComingSoon
          launchDate
          customText
        }
      }
    }
    allSliderImages {
      edges {
        node {
          url {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFeatCategories {
      nodes {
        name
        itemCount
        seoMetaData {
          slug
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 300, height: 300, quality: 100)
          }
        }
      }
    }
  }
`;
